<script setup lang="ts">
  import useCpBem from '~/composables/useCpBem';
  import CpIconStandard from '~/components/cp/CpIcon/CpIconStandard/CpIconStandard.vue';
  import CpText from '~/components/cp/CpText/CpText.vue';
  import type {ICpPillType} from '~/composables/useCpType';
  import {useI18n} from 'vue-i18n';


  withDefaults(defineProps<{
    short?: boolean,
    kind?: 'standard'|'discount',
    variant: ICpPillType,
    discount?: string | boolean,
    amount?: string,
    back?: boolean,
    blackBorder?: boolean,
  }>(), {
    kind: 'standard',
    variant: 'featuredProduct',
  });

  const {b, e, em} = useCpBem('cp-pill-card');
  const {t} = useI18n();

</script>

<template>
    <div :class="b">
        <div v-if="variant === 'offer-discount'" :class="em('container', { mkt: true, left: true, 'small-padding': true, 'black-border': blackBorder })">
            <CpText type="white" variant="caption-bold">
                -{{ discount }}%
            </CpText>
        </div>
        <div v-else-if="variant ==='featured-short'" :class="em('container', { mkt: true, left: true, 'feat-short': true, 'black-border': blackBorder })">
            <CpIconStandard kind="small" icon="magic_button" type="white" />
        </div>
        <div v-else-if="variant ==='featured-large'" :class="em('container', { mkt: true, left: true, 'black-border': blackBorder })">
            <CpText type="white" variant="caption-bold">
                {{ t('featured') }}
            </CpText>
            <CpIconStandard kind="small" icon="magic_button" type="white" />
        </div>
        <div v-else-if="variant === 'offer-label'" :class="em('container', { mkt: true, left: true, 'black-border': blackBorder })">
            <CpText type="white" variant="caption-bold">
                {{ t('offer') }}
            </CpText>
        </div>
        <div v-else-if="variant === 'featured-offer'" :class="em('container', { mkt: true, left: true, 'black-border': blackBorder })">
            <CpText type="white" variant="caption-bold">
                -{{ discount }}%
            </CpText>
            <CpIconStandard kind="small" icon="magic_button" type="white" />
        </div>
        <div v-else-if="variant === 'Oos'" :class="em('container', { 'neutral-extra-light': true, left: true, 'black-border': blackBorder })">
            <CpText type="white" variant="caption-bold">
                {{ t('outOfStock') }}
            </CpText>
        </div>
        <div v-else-if="variant ==='new-short'" :class="em('container', { primary: true, left: true, 'feat-short': true, 'black-border': blackBorder })">
            <CpIconStandard kind="small" icon="new_releases" type="white" />
        </div>
        <div v-else-if="variant ==='new-large'" :class="em('container', { primary: true, left: true, 'black-border': blackBorder })">
            <CpText type="white" variant="caption-bold">
                {{ t('newOne') }}
            </CpText>
            <CpIconStandard kind="small" icon="new_releases" type="white" />
        </div>

        <div v-else-if="variant ==='featuredProduct'" :class="em('container', {mkt: true, standard: true, 'black-border': blackBorder })">
            <CpText v-if="kind==='standard' && !short" type="white" variant="caption-bold">
                {{ t('featured') }}
            </CpText>
            <CpText v-if="kind==='discount'" type="white" variant="caption-bold">
                -{{ discount }}%
            </CpText>
            <CpIconStandard kind="small" icon="magic_button" type="white" />
        </div>
        <div v-if="variant === 'offer'" :class="em('container', {mkt: true, standard: true, 'black-border': blackBorder })">
            <CpText :class="e`discount`" type="white" variant="caption-bold">
                -{{ discount }}%
            </CpText>
        </div>
        <div v-else-if="variant === 'gift'" :class="em('container', {mkt: true, standard: true, 'black-border': blackBorder })">
            <CpText type="white" variant="caption-bold">
                {{ t('gift') }}
            </CpText>
            <CpIconStandard kind="small" icon="card_giftcard" type="white" />
        </div>
        <div v-else-if="variant === 'esd'" :class="em('container', {primary: true, standard: true, esdDisplay: short, 'black-border': blackBorder })">
            <CpText v-if="!short" type="white" variant="caption-bold">
                {{ t('downloadable') }}
            </CpText>
            <CpIconStandard kind="small" icon="download" type="white" :class="em('extra-width', {'back': back})" />
        </div>
        <div v-else-if="variant === 'oosEol'" :class="em('container', {neutral: true, standard: true, 'black-border': blackBorder })">
            <CpText type="white" variant="caption-bold">
                {{ t('outOfStock') }}
            </CpText>
        </div>
        <div v-else-if="variant === 'rowProduct'" :class="em('container', {neutral: true, standard: true, 'feat-short': short, 'black-border': blackBorder })">
            <CpText v-if="!short" type="white" variant="caption-bold">
                {{ t('imported') }}
            </CpText>
            <CpIconStandard kind="small" icon="public" type="white" :class="em('extra-width', {'back': back})" />
        </div>
        <div v-else-if="variant === 'eol'" :class="em('container', {white: true, rounded: true, 'black-border': blackBorder })">
            <CpText type="neutral" variant="caption-bold">
                {{ t('maybeOutOfMarket') }}
            </CpText>
        </div>
        <div v-else-if="variant === 'extendedTime'" :class="em('container', {white: true, rounded: true, 'black-border': blackBorder })">
            <CpText type="neutral" variant="caption-bold">
                {{ t('extendedDeliveryTime') }}
            </CpText>
        </div>
        <div v-else-if="variant === 'bundle'" :class="em('container', {mkt: true, rounded: true, 'black-border': blackBorder })">
            <CpText type="white" variant="caption-bold">
                {{ t('bundleDiscount', [amount]) }}
            </CpText>
        </div>
        <div v-else-if="variant === 'coupon'" :class="em('container', {mkt: true, rounded: true, 'black-border': blackBorder })">
            <CpText type="white" variant="caption-bold">
                {{ t('couponDiscount', [amount]) }}
            </CpText>
        </div>
        <div v-else-if="variant === 'special-offer'" :class="em('container', { mkt: true, left: true, 'black-border': blackBorder })">
            <CpText type="white" variant="caption-bold">
                {{ t('specialOffer') }}
            </CpText>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .cp-pill-card {
      width: fit-content;
      height: 20px;
      display: flex;
      &__container {
        display: flex;
        gap: 4px;
        justify-content: center;
        align-items: center;
        padding: 2px 8px;
        border: var(--border);
        border-right: 1px solid var(--color-white);
        border-bottom: 1px solid var(--color-white);

        &--black-border {
          border-right: 1px solid var(--color-neutral-dark);
          border-bottom: 1px solid var(--color-neutral-dark);
        }
        &--mkt {
          background: var(--color-mkt);
        }
        &--primary {
          background: var(--color-primary);
        }
        &--neutral {
          background: var(--color-neutral);
          padding: 4px 8px;
        }
        &--neutral-extra-light {
          background: var(--color-neutral-extra-light);
          padding: 4px 8px;
        }
        &--white {
          background: var(--color-white);
          border: 1px solid var(--color-neutral);
        }
        &--standard {
          border-radius: 8px 0;
        }
        &--rounded {
          border-radius: 16px;
          padding: 4px 8px;
        }
        &--right {
          border-radius: 0 0 0 8px;
        }
        &--left {
          border-radius: 0 0 8px 0;
        }
        &--esd-display {
          justify-content: flex-end;
        }
        &--small-padding {
          padding: 4px 8px;
        }
        &--feat-short {
          padding: 4px 8px 4px 16px;
        }
      }
      &__extra-width {
        &--back {
          padding: 0 $cp-gutter-small 0 $cp-gutter;
        }
      }
      &__discount {
        padding: 2px;
      }
    }
</style>

<i18n locale="es-US" lang="yaml">
  featured: 'Destacado'
  offer: 'Oferta'
  outOfStock: 'Agotado'
  newOne: 'Nuevo'
  gift: 'De regalo'
  downloadable: 'Descargable'
  imported: 'Importado'
  maybeOutOfMarket: 'Quizá fuera del mercado'
  extendedDeliveryTime: 'Tiempo de entrega prolongado'
  bundleDiscount: 'Descuento por bundle ${0}'
  couponDiscount: 'Descuento por cupón ${0}'
  specialOffer: 'Precio especial'
</i18n>
